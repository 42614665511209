import { Button, FallBack } from '@threecolts/ounce';

import {
  EmptyAssignProductSearchSvg,
  EmptyAssignProductToUserSvg,
  EmptyLinkedSearchSvg,
  EmptyLinkedSvg,
  EmptyNotificationSvg,
  EmptyOrderSearchSvg,
  EmptyOrderSvg,
  EmptyProductTypeSearchSvg,
  EmptyProductTypeSvg,
  EmptyTemplateSearchSvg,
  EmptyTemplateSvg,
  EmptyUnLinkedSearchSvg,
  EmptyUnLinkedSvg,
  NoBundleProductsSvg,
  NoImageFoundSvg,
  NoInternetConnectionSvg,
  NoPromotionSvg,
  PageNotFound404Svg,
} from './EmptySvg';

import React from 'react';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
// used
export const EmptyBulkEditGrid = () => (
  <FallBack
    illustration={<EmptyProductTypeSvg />}
    subTitle={'Seems like you havent any products for bulk edit.'}
    title='No Product Filter Available'
  />
);
export const EmptyProduct = () => (
  <FallBack
    illustration={<EmptyProductTypeSvg />}
    subTitle={t('NO_PRODUCT_AVAILABLE_DEC')}
    title={t('NO_PRODUCT_AVAILABLE')}
  />
);
export const EmptyProductSearch = () => (
  <FallBack
    illustration={<EmptyProductTypeSearchSvg />}
    subTitle={t('NO_PRODUCT_AVAILABLE_SEARCH_DEC')}
    title={t('NO_PRODUCT_AVAILABLE_SEARCH')}
    customClass='custom-no-product__fallback'
  />
);

export const EmptyOrder = () => (
  <FallBack
    illustration={<EmptyOrderSvg />}
    subTitle={t('NO_ORDER_AVAILABLE_DEC')}
    title={t('NO_ORDER_AVAILABLE')}
  />
);

export const EmptyOrderSearch = () => (
  <FallBack
    customClass='border-none'
    illustration={<EmptyOrderSearchSvg />}
    subTitle={t('NO_ORDER_AVAILABLE_SEARCH_DEC')}
    title={t('NO_ORDER_AVAILABLE_SEARCH')}
  />
);

export const EmptyRetrun = () => (
  <FallBack
    illustration={<EmptyOrderSvg />}
    subTitle={t('NO_RETURN_AVAILABLE_DEC')}
    title={t('NO_RETURN_AVAILABLE')}
  />
);

export const EmptyRetrunSearch = () => (
  <FallBack
    illustration={<EmptyOrderSearchSvg />}
    subTitle={t('NO_RETURN_AVAILABLE_SEARCH_DEC')}
    title={t('NO_RETURN_AVAILABLE_SEARCH')}
    customClass='custom-no-product__fallback'
  />
);

export const EmptyUnLinked = () => (
  <FallBack
    illustration={<EmptyUnLinkedSvg />}
    subTitle={t('NO_LINKABLE_PRODUCT_AVAILABLE_DEC')}
    title={t('NO_LINKABLE_PRODUCT_AVAILABLE')}
  />
);
export const EmptyUnLinkedSearch = () => (
  <FallBack
    customClass='border-none'
    illustration={<EmptyUnLinkedSearchSvg />}
    subTitle={t('NO_LINKABLE_PRODUCT_AVAILABLE_SEARCH_DEC')}
    title={t('NO_LINKABLE_PRODUCT_AVAILABLE_SEARCH')}
  />
);

export const EmptyLinked = () => (
  <FallBack
    illustration={<EmptyLinkedSvg />}
    subTitle={t('NO_LINKED_PRODUCT_AVAILABLE_DEC')}
    title={t('NO_LINKED_PRODUCT_AVAILABLE')}
  />
);

export const EmptyLinkedSearch = () => (
  <FallBack
    illustration={<EmptyLinkedSearchSvg />}
    subTitle={t('NO_LINKED_PRODUCT_AVAILABLE_SEARCH_DEC')}
    title={t('NO_LINKED_PRODUCT_AVAILABLE_SEARCH')}
    customClass='custom-no-product__fallback'
  />
);

export const EmptyTemplate = () => (
  <FallBack
    illustration={<EmptyTemplateSvg />}
    subTitle={t('NO_CATEGORY_FOUND_IN_FULLPAGE_DESCRIPTION')}
    title={t('NO_CATEGORY_FOUND_IN_FULLPAGE_TITILE')}
  />
);
export const EmptyTemplateSearch = () => (
  <FallBack
    illustration={<EmptyTemplateSearchSvg />}
    subTitle={t('NO_CATEGORY_FOUND_IN_SEARCH_DESCRIPTION')}
    title={t('NO_CATEGORY_FOUND_IN_SEARCH_TITILE')}
    customClass='custom-no-product__fallback'
  />
);

export const PageNotFound404 = () => (
  <FallBack
    illustration={<PageNotFound404Svg />}
    subTitle={t('PAGE_NOT_FOUND_DEC')}
    action={<Button content='Go Back' type='outlined' />}
    title={t('PAGE_NOT_FOUND_TITLE')}
  />
);

export const EmptyNotification = (_props: { emptyText?: string }) => (
  <FallBack
    customClass='border-none'
    illustration={<EmptyNotificationSvg />}
    subTitle={<Trans i18nKey='WE_COULD_NOT_FIND_ANY_ACTIVITY' />}
    title={_props.emptyText ?? <Trans i18nKey='NO_RECENT_ACTIVITIES' />}
  />
);

export const EmptyAnnouncement = () => (
  <FallBack
    customClass='border-none'
    illustration={<EmptyNotificationSvg />}
    subTitle='There is no annoucement found in this page'
    title='No Annoucement Available'
  />
);

export const NoInternetConnection = () => (
  <FallBack
    customClass='border-none custom-no-internet'
    illustration={<NoInternetConnectionSvg />}
    subTitle={
      'We couldn’t connect to the Internet. Please check your connection.'
    }
    title='Internet Connection Interrupted!'
  />
);

export const NoImageFound = () => (
  <FallBack
    customClass='border-none'
    illustration={<NoImageFoundSvg />}
    title='No Image Found'
  />
);

export const NoBundleProducts = () => (
  <FallBack
    title={'No Bundle Product Available'}
    subTitle='We couldn’t find any Bundle product in this page.'
    illustration={<NoBundleProductsSvg />}
  />
);

export const NoPromotions = () => (
  <div className='no_promotion_fallback'>
    <FallBack
      title={'No promotion list is there'}
      subTitle='We couldn’t find any promotion in this page. '
      illustration={<NoPromotionSvg />}
    />
  </div>
);

export const NoPromotionProducts = ({
  setOpenSelectModal,
  isOpenSelectModal,
}: any) => (
  <div className='no_promotio_product_fallback'>
    <FallBack
      title={'No Product Selected'}
      subTitle='We couldn’t find any product in this page.'
      illustration={<EmptyProductTypeSvg />}
      action={
        <Button
          type='secondary'
          size='thin'
          onClick={() => {
            setOpenSelectModal(!isOpenSelectModal);
          }}>
          Select Products
        </Button>
      }
    />
  </div>
);
export const EmptyAssignProductToUser = () => (
  <FallBack
    title='No Linked Product Found'
    subTitle="Seems like you haven't added any linked product"
    illustration={<EmptyAssignProductToUserSvg />}
  />
);

export const EmptyAssignProductToUserSearch = () => (
  <FallBack
    title='No Search Result Found'
    subTitle='We couldn’t find any result matching the given criteria.'
    illustration={<EmptyAssignProductSearchSvg />}
  />
);
